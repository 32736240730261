<template>
  <client-only>
    <div v-click-outside="hide" class="month-picker-input-container">
      <input
        v-model="selectedDate"
        class="month-picker-input w-100"
        type="text"
        :placeholder="placeholder"
        readonly
        @click="showMonthPicker()"
      >
      <month-picker
        v-show="monthPickerVisible"
        v-model="inputValue"
        :lang="lang"
        :months="months"
        :no-default="noDefault"
        :show-year="showYear"
        :highlight-exact-date="highlightExactDate"
        :clearable="clearable"
        :variant="variant"
        :editable-year="editableYear"
        :max-date="maxDate"
        :min-date="minDate"
        :year-only="yearOnly"
        @input="populateInput"
        @change="updateDate"
      />
    </div>
  </client-only>
</template>

<script>
import moment from 'moment'
import MonthPicker from './MonthPicker.vue'
import monthPicker from './month-picker-mix'

export default {
  name: 'MonthPickerInput',
  directives: {
    clickOutside: {
      bind (el, binding, vnode) {
        el.event = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.event)
      },
      unbind (el) {
        document.body.removeEventListener('click', el.event)
      },
      beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value()
          }
        }
        document.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: (el) => {
        document.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  components: {
    MonthPicker
  },
  mixins: [monthPicker],
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  emits: ['change', 'input'],
  data () {
    return {
      monthPickerVisible: false,
      selectedDate: null,
      inputValue: this.value
    }
  },
  watch: {
    defaultYear: {
      immediate: true,
      handler (value) {
        if (!value || !this.inputPreFilled) { return }
        const formatedDate = moment().set('year', value).set('month', this.defaultMonth - 1).format(this.dateFormat)
        const selectedDate = moment().set('year', value).set('month', this.defaultMonth - 1).format(this.displayFormat)
        this.selectedDate = selectedDate
        this.inputValue = formatedDate
      }
    },
    defaultMonth: {
      immediate: true,
      handler (value) {
        if (!value || !this.inputPreFilled) { return }
        const selectedDate = moment().set('year', this.defaultYear).set('month', value - 1).format(this.displayFormat)
        const formatedDate = moment().set('year', this.defaultYear).set('month', value - 1).format(this.dateFormat)
        this.selectedDate = selectedDate
        this.inputValue = formatedDate
      }
    },
    value: {
      immediate: true,
      handler (value) {
        const formatedDate = moment(value).format(this.dateFormat)
        const selectedDate = moment(value).format(this.displayFormat)
        this.selectedDate = selectedDate
        this.inputValue = formatedDate
      }
    }
  },
  mounted () {
    this.selectedDate = moment(this.value).format(this.displayFormat)
  },
  methods: {
    populateInput (date) {
      const formatedDate = moment().set('year', date.year).set('month', date.month).format(this.dateFormat)
      const selectedDate = moment().set('year', date.year).set('month', date.month).format(this.displayFormat)
      this.selectedDate = selectedDate
      this.inputValue = formatedDate

      this.monthPickerVisible = false
      this.$emit('input', formatedDate)
    },
    showMonthPicker () {
      this.monthPickerVisible = !this.monthPickerVisible
    },
    hide () {
      this.monthPickerVisible = false
    },
    updateDate (date) {
      const formatedDate = moment().set('year', date.year).set('month', date.month).format(this.dateFormat)
      const selectedDate = moment().set('year', date.year).set('month', date.month).format(this.displayFormat)
      this.selectedDate = selectedDate
      this.inputValue = formatedDate
      this.$emit('change', formatedDate)
    }
  }
}
</script>
<style scoped>
.month-picker-input-container {
    width: 100%;
    min-width: 140px;
}

.month-picker-input {
    padding: 0.25rem 0.5rem;
    font-size: 0.85em;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.month-picker-input:focus {
    border-color: rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.month-picker__container {
    position: absolute;
    top: 3.5em;
}
</style>
