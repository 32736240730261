<template>
  <div
    class="date-range d-inline-flex w-100"
  >
    <b-form-datepicker
      v-model="from"
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
      :max="to"
      size="sm"
      locale="en-GB"
      :class="inputFromCls"
    />
    <div class="mx-0 mx-md-1 mx-lg-2">
      &nbsp;-&nbsp;
    </div>
    <b-form-datepicker
      v-model="to"
      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
      :min="from"
      size="sm"
      locale="en-GB"
      :class="inputToCls"
    />
  </div>
</template>

<script>
export default {
  name: 'DateRange',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    variant: {
      type: [String],
      default: ''
    }
  },
  data () {
    return {
      from: this.value?.from,
      to: this.value?.to
    }
  },
  computed: {
    inputFromCls () {
      if (this.variant && this.from) {
        return `border-${this.variant} text-${this.variant}`
      }
      return ''
    },
    inputToCls () {
      if (this.variant && this.to) {
        return `border-${this.variant} text-${this.variant}`
      }
      return ''
    }
  },
  watch: {
    value: {
      handler (n) {
        this.from = n?.from
        this.to = n?.to
      },
      deep: true
    },
    from (n) {
      this.$emit('input', this.getValue())
    },
    to (n) {
      this.$emit('input', this.getValue())
    }
  },
  methods: {
    getValue () {
      const value = {}
      if (this.from) {
        value.from = this.from
      }
      if (this.to) {
        value.to = this.to
      }
      return value
    }
  }
}
</script>

<style lang="scss">
.date-range {
  .b-form-datepicker {
    &.text-info {
      .btn, .form-control  {
        color: var(--info) !important;
      }
    }
  }
}
</style>
